<template>
  <div class="login-container">
    <div class="centercontain">
      <div class="left">
        <img class="backimage" :src="icon2" />
        <div class="logocintain">{{ loginText }}</div>
      </div>

      <div class="right" v-if="changeform">
        <div class="logoitem">
          <img class="logo" :src="icon1" />
          <!-- <img class="logo" :src="icon1" v-if="schoolid == 1" />
          <img class="logo" :src="icon1_1" v-else />-->
        </div>
        <div class="logoitem logoitem2">
          <img class="logo" :src="icon7" />
        </div>
        <el-form ref="loginForm" :rules="loginRules" :model="loginForm" class="elform">
          <el-form-item label class="elitem" prop="username">
            <div class="inputcontain">
              <img class="user" :src="icon5" />
              <el-input
                class="elinput"
                v-model.trim="loginForm.username"
                placeholder="请输入学工号/账号/电子邮箱/手机号"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label class="elitem elitem2" prop="password">
            <div class="inputcontain">
              <img class="user" :src="icon3" />
              <el-input
                class="elinput"
                v-model.trim="loginForm.password"
                placeholder="请输入密码"
                show-password
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <!-- <div class="tsy" v-if="isMobile == 0">
          <span>温馨提示：学生登录用户名为学号，初始密码为学号后6位</span>
        </div>-->
        <div class="logoitem passtip">
          <div class="contain">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <!-- <div class="contain_reg register" @click="register('forget')">
              <div>忘记密码?</div>
            </div>-->
            <div class="el-checkbox__label">
              <div class="contain_reg register" @click="register('forget')">
                <div>忘记密码?</div>
              </div>
              <div class="contain_reg register" @click="register('register')">
                <div>注册账号</div>
              </div>
            </div>
          </div>
        </div>
        <div class="logoitem logbt">
          <el-button class="bt" v-if="loading">
            <span class="el-icon-loading loadingIcon"></span>
            <span class="loadingText">正在登录中</span>
          </el-button>
          <el-button class="bt" v-else @click="handleLogin">登录</el-button>
          <!-- <el-button @click="authentication">统一身份认证</el-button> -->
        </div>
        <div class="logoitem logbt" v-if="auth_login_sso_direct">
          <el-button class="bt" @click="authentication">统一身份认证</el-button>
        </div>
      </div>
      <div class="right" v-if="!changeform">
        <div class="logoitem">
          <img class="logo" :src="icon1" />
          <!-- <img class="logo" :src="icon1" v-if="schoolid == 1" />
          <img class="logo" :src="icon1_1" v-else />-->
        </div>
        <div class="logoitem logoitem2">
          <img class="logo" :src="icon7" />
        </div>
        <!-- 首次登录修改密码-->
        <el-form ref="changeForm" :rules="changeRules" :model="changeForm" class="elform">
          <el-form-item label class="elitem elitem2" prop="pass0">
            <div class="inputcontain">
              <img class="user" :src="icon3" />
              <el-input
                v-model.trim="changeForm.pass0"
                class="elinput"
                id="elinput"
                placeholder="请输入原密码"
                show-password
                clearable
              />
            </div>
          </el-form-item>
          <el-form-item label class="elitem elitem2" prop="pass">
            <div class="inputcontain">
              <img class="user" :src="icon3" />
              <el-input
                v-model.trim="changeForm.pass"
                class="elinput"
                id="elinput"
                placeholder="请输入新密码"
                show-password
                clearable
              />
            </div>
          </el-form-item>
          <el-form-item label class="elitem elitem2" prop="checkPass">
            <div class="inputcontain">
              <img class="user" :src="icon3" />
              <el-input
                v-model.trim="changeForm.checkPass"
                class="elinput"
                id="elinput"
                placeholder="请输入确认密码"
                show-password
                clearable
              />
            </div>
          </el-form-item>
        </el-form>
        <div class="logoitem passtip">
          <div class="contain" style="width: 55%">
            <div class="el-checkbox__label" style="width: 100%">
              <div
                class="contain_reg register"
                @click="modifiedtemporarily"
                style="float: left; width: 70%; margin-right: 0"
              >
                <div>暂不修改密码</div>
              </div>
              <div class="contain_reg register" @click="promptagain" style="margin-right: 0">
                <div>不再提示修改密码</div>
              </div>
            </div>
          </div>
        </div>
        <div class="logoitem logbt" v-if="!changeform">
          <el-button class="bt" @click="edituserresetpassword">提交</el-button>
        </div>
      </div>
    </div>

    <el-dialog
      class="my_eldialog"
      title
      :visible.sync="dialogVisible"
      width="34%"
      :show-close="false"
      :close-on-click-modal="false"
      top="25vh"
    >
      <div class="dialog_con">
        <div class="title_t">请选择身份</div>
        <div class="icon_con">
          <div class="item_con" v-if="is_inarr('1')" @click="btclick(1)">
            <div class="el_icon">
              <img class="img_icon" :src="icon00" />
              <img v-if="btid == 1" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">学生</div>
          </div>
          <div class="item_con" v-if="is_inarr('2')" @click="btclick(2)">
            <div class="el_icon">
              <img class="img_icon" :src="icon01" />
              <img v-if="btid == 2" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">老师</div>
          </div>
          <div class="item_con" v-if="is_inarr('3')" @click="btclick(3)">
            <div class="el_icon">
              <img class="img_icon" :src="icon02" />
              <img v-if="btid == 3" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">管理员</div>
          </div>
          <div class="item_con" v-if="is_inarr('4')" @click="btclick(4)">
            <div class="el_icon">
              <img class="img_icon" :src="icon03" />
              <img v-if="btid == 4" class="img_icon" :src="icon04" />
            </div>
            <div class="text_t">校外人员</div>
          </div>
        </div>
        <div class="bt_con">
          <el-button type="primary" class="bt" @click="bt_enter">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { delCookie } from "@/utils/cookie"
import { getuserinfo, edituserresetpassword, useredit } from "@/api/user"
// import { getschoolinfo } from "@/api/school.js"
import {
  getIdentifierToken,
  setIdentifierToken,
  setWebNameToken,
  setWebAvaterToken,
} from "@/utils/auth"
const request_base_path = require("../../../src/utils/base_url")
const systemConfig = sessionStorage.getItem("systemConfig") || ""
const systemInfo = systemConfig ? JSON.parse(systemConfig) : ""
import { mapState } from "vuex"
export default {
  name: "Login",
  components: {},
  data () {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入正确的登录密码"))
      } else {
        callback()
      }
    }
    const repeatValidate = (rule, value, callback) => {
      if (value !== this.changeForm.pass) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }

    const newValValidate = (rule, value, callback) => {
      if (value === this.changeForm.pass0) {
        callback(new Error("新密码不能与旧密码一致"))
      } else if (
        /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/.test(value)
      ) {
        callback()
      } else {
        callback(
          new Error("请输入至少7位以上含字母、数字或特殊符号的组合密码")
        )
      }
    }
    return {
      btid: -1,
      dialogVisible: false,
      identifiers: "",
      icon1: "",
      // icon1_1: require("@/assets/shifan02.png"),
      icon2: require("@/assets/登录/矩形 1.png"),
      icon3: require("@/assets/登录/密码.png"),
      icon4: require("@/assets/登录/bg1.jpg"),
      icon5: require("@/assets/登录/用户.png"),
      icon7: require("@/assets/登录/组 1.png"),
      icon00: require("@/assets/学生.png"),
      icon01: require("@/assets/老师.png"),
      icon02: require("@/assets/管理员.png"),
      icon03: require("@/assets/校外人员.png"),
      icon04: require("@/assets/遮罩.png"),
      checked: false,
      loginForm: {
        username: "", // 手机号格式
        password: "",
      },
      changeForm: {
        pass0: "", // 初始密码
        pass: "", // 新密码
        checkPass: "", // 验证密码
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      changeRules: {
        pass0: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        pass: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 7,
            message: "密码设置长度最少为7位",
            trigger: "blur",
          },
          { validator: newValValidate, trigger: "blur" },
        ],
        checkPass: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: repeatValidate, trigger: "blur" },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      // schoolid: "",
      // schoolinfo: {},
      isMobile: "",
      changeform: true,
      loginText: "",
      auth_login_sso_direct: systemInfo.auth_login_sso_direct,
    }
  },
  computed: {
    ...mapState(["websiteInfo"]),
  },
  watch: {
    websiteInfo: {
      handler (newV, oldV) {
        this.setSiteInfo(newV)
      },
      immediate: true,
      deep: true,
    },
  },
  created () {
    // this.schoolid = localStorage.getItem("schoolid") * 1 || 1
    // let info = localStorage.getItem("schoolinfo")
    // if (info) {
    //   this.schoolinfo = JSON.parse(info)
    // } else {
    //   getschoolinfo({}).then((res) => {
    //     if (res && res.code === 0) {
    //       this.schoolinfo = res.data
    //     }
    //   })
    // }
    if (this._isMobile()) {
      this.isMobile = 0
    } else {
      this.isMobile = 1
    }
    delCookie()
  },
  mounted () {
    this.getCookie()
    // this.getDataList();
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    btclick (id) {
      this.btid = id
    },
    bt_enter () {
      if (this.btid == -1) {
        this.$message({
          message: "请选择一个身份!",
          type: "warning",
        })
      } else {
        this.dialogVisible = false
        setIdentifierToken(this.btid)
        this.$router.push({ path: "/home" })
        this.getuserinfo()
      }
    },
    is_inarr (num) {
      return this.identifiers.includes(num)
    },
    // 登录
    handleLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let strTip =
            "特别说明：为保障师生员工数据安全，请用户勿使用第三方软件(或APP) 模拟登陆教学管理系统、学生管理系统、智慧实践教学平台等。对第三方软件(或APP) 模拟登陆获取师生员工数据的行为，将对相关服务器IP封禁。"
          this.$confirm(strTip, {
            confirmButtonText: "继续登录",
            cancelButtonText: "取消",
            showClose: false,
            center: true,
          })
            .then(() => {
              this.loading = true
              this.$store
                .dispatch("login", this.loginForm)
                .then(async (res) => {
                  if (res.data.password_strength_tips) {
                    if (
                      res.data.password_strength_tips.need_change_password === 1
                    ) {
                      this.changeform = false
                      this.$message.error(
                        res.data.password_strength_tips.message
                      )
                      this.getuserinfo()
                      return false
                    }
                  }
                  this.identifiers = getIdentifierToken()
                  if (this.identifiers) {
                    this.identifiers = this.identifiers.split(",")
                    if (this.identifiers.length >= 1) {
                      setIdentifierToken(this.identifiers[0])
                      this.$store.commit("SET_Identifier", this.identifiers[0])
                      let res = await getuserinfo() // 新增接口请求code:16时，登录会短暂跳转到首页再次跳转到/pageClosed页，逻辑处理
                      try {
                        if (res.code === 16) {
                          return false
                        } else if (res.code === 0) {
                          this.$store.commit("SET_Username", res.data.name)
                          localStorage.setItem("username", res.data.name)
                          localStorage.setItem("usernumber", res.data.username)
                          setWebNameToken(res.data.name)
                          this.$store.commit("SET_Useravater", res.data.avatar)
                          setWebAvaterToken(res.data.avatar)
                        }
                        setTimeout(() => {
                          // if(local)
                          if (localStorage.getItem("login_path")) {
                            this.$router.push({
                              path: localStorage.getItem("login_path"),
                            })
                            window.localStorage.setItem("menuId", 2)
                            this.$store.dispatch("setmenuid", 2)
                          } else {
                            this.$router.push({ path: "/home" })
                          }

                          localStorage.setItem("live_login_flag", "0")
                        }, 1 * 1000)
                      } catch (error) { }
                    } else {
                      this.dialogVisible = true
                    }
                  }
                  if (this.checked == true) {
                    //传入账号名，密码，和保存天数3个参数
                    this.setCookie(
                      this.loginForm.username,
                      this.loginForm.password,
                      7
                    )
                  } else {
                    //清空Cookie
                    this.clearCookie()
                  }
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            })
            .catch(() => {
              return false
            })
        } else {
          return false
        }
      })
    },

    // 暂不修改
    modifiedtemporarily () {
      this.$router.push({ path: "/home" })
    },
    promptagain () {
      let par = {
        ignore_password_change_tip: 1,
      }
      useredit(par).then((res) => {
        if (res.code === 0) {
          this.$router.push({ path: "/home" })
        }
      })
    },
    edituserresetpassword () {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          var params = {
            old_password: this.changeForm.pass0,
            password: this.changeForm.pass,
          }
          edituserresetpassword(params)
            .then((response) => {
              if (response.code === 0) {
                this.$message({
                  message: "修改成功,请重新登录",
                  type: "success",
                })
                this.changeform = true
                this.loading = false
              }
            })
            .catch((error) => {
              //console.log(error);
            })
        }
      })
    },
    //设置cookie
    setCookie (c_name, c_pwd, exdays) {
      var exdate = new Date() //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString()
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString()
    },
    //读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; ") //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("=") //再次切割
          //判断查找相对应的值
          if (arr2[0] == "userName") {
            this.loginForm.username = arr2[1] //保存到保存数据的地方
          } else if (arr2[0] == "userPwd") {
            this.loginForm.password = arr2[1]
            this.checked = true
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1) //修改2值都为空，天数为负1天就好了
    },
    getuserinfo (params) {
      getuserinfo(params)
        .then((response) => {
          if (response.code === 0) {
            this.$store.commit("SET_Username", response.data.name)
            localStorage.setItem("username", response.data.name)
            localStorage.setItem("usernumber", response.data.username)
            setWebNameToken(response.data.name)
            this.$store.commit("SET_Useravater", response.data.avatar)
            setWebAvaterToken(response.data.avatar)
          }
        })
        .catch((error) => { })
    },
    //用户注册
    register (data) {
      this.$router.push({
        path: "/register",
        query: {
          flag: data,
        },
      })
    },

    authentication () {
      if (process.env.NODE_ENV == "development") {
        let str = request_base_path.dev_url + "#/sign_on"
        location.href =
          request_base_path.dev_url +
          "sso-login?fromtype=3&redirect_url=" +
          encodeURIComponent(str)
      } else if (process.env.NODE_ENV == "production") {
        let str = request_base_path.pro_url + "#/sign_on"
        location.href =
          request_base_path.pro_url +
          "sso-login?fromtype=3&redirect_url=" +
          encodeURIComponent(str)
      }
    },
    setSiteInfo (data) {
      this.icon1 = data.portal_head_logo
      this.loginText = data.portal_name
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .login-container {
    height: 100%;
    width: 100%;
    position: relative;
    background: url('../../assets/登录/bg1.jpg') 0 0 no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .centercontain {
      height: auto;
      // display: flex;
      width: 95%;
      margin: 0 auto;

      .left {
        position: relative;
        // width: 518px;
        display: none;

        .logocintain {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          color: #ffffff;
          letter-spacing: 0.1em;
          padding: 0px 40px;
          text-align: center;
        }

        .backimage {
          margin-bottom: -4px;
          height: 100%;
          width: 100%;
        }
      }

      .right {
        flex: 1;
        background: #ffffff;
        padding-bottom: 20px;
        padding-top: 20px;
        border-radius: 10px;

        .logoitem {
          margin-top: 60px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          width: 95%;
          margin: 0 auto;
          text-align: center;

          .inputcontain {
            width: 100%;
            height: 45px;
            border: 1px solid #26b5ff;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto;

            .user {
              margin-left: 20px;
            }

            .elinput {
              ::v-deep .el-input__inner {
                border: none;
              }
            }
          }
        }

        .logoitem2 {
          margin-top: 50px;

          .logo {
            width: 100%;
          }
        }

        .elform {
          .elitem {
            margin-top: 30px;
            // display: flex;
            // justify-content: center;
            // align-items: center;

            .inputcontain {
              width: 95%;
              height: 45px;
              border: 1px solid #26b5ff;
              border-radius: 10px;
              padding: 0px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin: 0 auto;

              .user {
                margin-left: 20px;

                width: 20px;
                height: 20px;
              }

              .elinput {
                ::v-deep .el-input__inner {
                  border: none;
                }
              }
            }
          }

          .elitem2 {
            margin-top: 31px;
          }
        }

        .passtip {
          margin-top: 10px;

          .contain {
            width: 100%;
            height: 30px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;

            .el-checkbox__label {
              cursor: pointer;
              display: flex;
            }
          }

          .contain_reg {
            cursor: pointer;
            margin-right: 10px;
          }
        }

        .logbt {
          margin-top: 20px;

          .bt {
            width: 100%;
            height: 45px;
            color: white;
            background: #26b5ff;
            border-radius: 10px;
            margin: 0 auto;
          }

          .loadingIcon {
            font-size: 20px;
          }

          .loadingText {
            transform: translateY(-2px);
            display: inline-block;
          }
        }
      }
    }

    .my_eldialog {
      .dialog_con {
        width: 100%;
        height: 340px;

        .title_t {
          text-align: center;
          font-size: 22px;
          font-weight: bold;
          color: #26b5ff;
        }

        .icon_con {
          margin: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          //  border: 1px solid red;
          .item_con {
            margin-right: 20px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .el_icon {
              position: relative;
              width: 80px;
              height: 80px;

              .img_icon {
                position: absolute;
                top: 0px;
              }
            }

            .text_t {
              margin-top: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
            }
          }
        }

        .bt_con {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          .bt {
            border-radius: 20px;
            width: 220px;
            height: 50px;
          }
        }
      }
    }
  }

  ::v-deep .el-dialog {
    box-shadow: 1px 0px 6px 2px #ccc !important;
    border-radius: 10px !important;
  }

  ::v-deep .el-dialog__header {
    padding: 0px;
  }

  .tsy {
    font-size: 13px;
    margin-left: 10px;
    height: 35px;
    line-height: 35px;
    color: red;
    position: relative;
    top: -20px;
    font-weight: bold;
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .login-container {
    height: 100%;
    width: 100%;
    position: relative;
    background: url('../../assets/登录/bg1.jpg') 0 0 no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .centercontain {
      height: auto;
      display: flex;
      width: 1286px;

      .left {
        position: relative;
        // width: 481px;
        display: none;

        .logocintain {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 32px;
          color: #ffffff;
          letter-spacing: 0.1em;
          padding: 0px 40px;
          text-align: center;
        }

        .backimage {
          margin-bottom: -4px;
          height: 100%;
          width: 100%;
        }
      }

      .right {
        flex: 1;
        background: #ffffff;

        .logoitem {
          margin-top: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          .inputcontain {
            width: 411px;
            height: 60px;
            border: 1px solid #26b5ff;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user {
              margin-left: 20px;
            }

            .elinput {
              ::v-deep .el-input__inner {
                border: none;
              }
            }
          }
        }

        .logoitem2 {
          margin-top: 78px;
        }

        .elform {
          .elitem {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            .inputcontain {
              width: 411px;
              height: 60px;
              border: 1px solid #26b5ff;
              border-radius: 10px;
              padding: 0px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .user {
                margin-left: 20px;

                width: 27px;
                height: 27px;
              }

              .elinput {
                ::v-deep .el-input__inner {
                  border: none;
                }
              }
            }
          }

          .elitem2 {
            margin-top: 31px;
          }
        }

        .passtip {
          margin-top: 10px;

          .contain {
            width: 411px;
            height: 30px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;

            .el-checkbox__label {
              cursor: pointer;
              display: flex;
            }
          }

          .contain_reg {
            cursor: pointer;
            margin-right: 10px;
          }
        }

        .logbt {
          margin-top: 35px;

          .bt {
            width: 411px;
            height: 60px;
            color: white;
            background: #26b5ff;

            border-radius: 10px;
          }

          .loadingIcon {
            font-size: 20px;
          }

          .loadingText {
            transform: translateY(-2px);
            display: inline-block;
          }
        }
      }
    }

    .my_eldialog {
      .dialog_con {
        width: 100%;
        height: 340px;

        .title_t {
          text-align: center;
          font-size: 22px;
          font-weight: bold;
          color: #26b5ff;
        }

        .icon_con {
          margin: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          //  border: 1px solid red;
          .item_con {
            margin-right: 20px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .el_icon {
              position: relative;
              width: 80px;
              height: 80px;

              .img_icon {
                position: absolute;
                top: 0px;
              }
            }

            .text_t {
              margin-top: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
            }
          }
        }

        .bt_con {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          .bt {
            border-radius: 20px;
            width: 220px;
            height: 50px;
          }
        }
      }
    }
  }

  ::v-deep .el-dialog {
    box-shadow: 1px 0px 6px 2px #ccc !important;
    border-radius: 10px !important;
  }

  ::v-deep .el-dialog__header {
    padding: 0px;
  }

  .register {
    display: flex;
    color: #26b5ff;
  }

  // .tsy{
  //   width: 77%;
  //   margin: 0 auto;
  // }
}

@media screen and (min-width: 1200px) {
  .login-container {
    height: 100%;
    width: 100%;
    position: relative;
    background: url('../../assets/登录/bg1.jpg') 0 0 no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .centercontain {
      height: auto;
      display: flex;
      width: 1286px;

      .left {
        position: relative;
        // width: 533px;

        .logocintain {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          color: #ffffff;
          letter-spacing: 0.1em;
          padding: 0px 60px;
          text-align: center;

          .logo {
          }
        }

        .backimage {
          margin-bottom: -4px;
          height: 100%;
          width: 100%;
        }
      }

      .right {
        flex: 1;
        background: #ffffff;
        padding-bottom: 20px;

        .logoitem {
          margin-top: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          .inputcontain {
            width: 411px;
            height: 60px;
            border: 1px solid #26b5ff;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user {
              margin-left: 20px;
            }

            .elinput {
              ::v-deep .el-input__inner {
                border: none;
              }
            }
          }
        }

        .logoitem2 {
          margin-top: 78px;
        }

        .elform {
          .elitem {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            .inputcontain {
              width: 411px;
              height: 60px;
              border: 1px solid #26b5ff;
              border-radius: 10px;
              padding: 0px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .user {
                margin-left: 20px;

                width: 27px;
                height: 27px;
              }

              .elinput {
                ::v-deep .el-input__inner {
                  border: none;
                }
              }
            }
          }

          .elitem2 {
            margin-top: 31px;
          }
        }

        .passtip {
          margin-top: 10px;

          .contain {
            width: 411px;
            height: 30px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-left: 14px;
            padding-right: 14px;

            .el-checkbox__label {
              cursor: pointer;
              display: flex;
            }
          }

          .contain_reg {
            cursor: pointer;
            margin-right: 10px;
          }
        }

        .logbt {
          margin-top: 35px;

          .bt {
            width: 411px;
            height: 60px;
            color: white;
            background: #26b5ff;

            border-radius: 10px;
          }

          .loadingIcon {
            font-size: 20px;
          }

          .loadingText {
            transform: translateY(-2px);
            display: inline-block;
          }
        }
      }
    }

    .my_eldialog {
      .dialog_con {
        width: 100%;
        height: 340px;

        .title_t {
          text-align: center;
          font-size: 22px;
          font-weight: bold;
          color: #26b5ff;
        }

        .icon_con {
          margin: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          //  border: 1px solid red;
          .item_con {
            margin-right: 20px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .el_icon {
              position: relative;
              width: 80px;
              height: 80px;

              .img_icon {
                position: absolute;
                top: 0px;
              }
            }

            .text_t {
              margin-top: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
            }
          }
        }

        .bt_con {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          .bt {
            border-radius: 20px;
            width: 220px;
            height: 50px;
          }
        }
      }
    }
  }

  ::v-deep .el-dialog {
    box-shadow: 1px 0px 6px 2px #ccc !important;
    border-radius: 10px !important;
  }

  ::v-deep .el-dialog__header {
    padding: 0px;
  }

  .register {
    display: flex;
    color: #26b5ff;
  }
}
</style>
<style>
@media screen and (max-width: 640px) {
  .el-message-box {
    width: 95%;
  }
}
</style>